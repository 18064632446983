<template>
  <div>
    <!--    head-->
    <Head></Head>
    <router-view></router-view>
    <!--    footer-->
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '../components/head';
import Footer from '../components/footer';

export default {
  name: "index",
  components: {Head, Footer},
}
</script>

<style scoped>

</style>