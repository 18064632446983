<template>
  <div class="container">
    <div class="content felx flex-space">
      <router-link to="/">
        <img src="../assets/images/logo.png" class="logo">
      </router-link>

      <div class="felx flex-a">
        <span class="text">助贷中小微企业</span>
        <img src="../assets/images/more@2x.png" class="more" @click="onChange">
      </div>
    </div>
    <!--    菜单-->
    <div class="menu" v-if="showMenu">
      <div class="triangle_border_up"></div>
      <div class="menu-box">
        <router-link class="menu-item" to="/">首页</router-link>
        <router-link class="menu-item" to="/apply-immediately">立即贷款</router-link>
        <router-link class="menu-item" to="/loan-amount-measurement">贷款测额</router-link>
        <router-link class="menu-item" to="/loan-info">贷款资讯</router-link>
        <router-link class="menu-item" to="/company-profile">关于我们</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "Head",
  data() {
    return {
      // showMenu: false,//显示菜单
    }
  },
  computed: {...mapState(['showMenu'])},
  methods:{
    onChange(){
      this.$store.commit('changeMenu',!this.showMenu)
    },
  },

}
</script>

<style scoped>
/*.container {*/
/*position: relative;*/
/*}*/

.content {
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 0 15px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}

.logo {
  width: 115px;
  height: 24px;
}

.more {
  width: 17px;
  height: 13px;
  margin-left: 28px;
}

.text {
  font-size: 13px;
  color: #999999;
}

/*菜单*/
.menu {
  position: fixed;
  top: 60px;
  right: 15px;
  z-index: 9;
}

.triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 7px 10px;
  border-style: solid;
  border-color: transparent transparent #F8F8F8; /*透明 透明  灰*/
  position: relative;
  right: -75px;
}

.menu-box {
  width: 90px;
  background-color: #F8F8F8;
  padding: 0 10px;
}

.menu-item {
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 13px;
  color: #333333;
  border-bottom: 1px solid #DDDDDD;
  display: block;
  line-height: 40px;
}

.menu-item:last-child {
  border-bottom: none;
}

</style>