<template>
  <div class="footer">


<div class="container felx flex-center felx-d">
  <div>德润消费大数据(重庆)有限公司</div>
  <a href="tel:023-67456888" class="phone">联系电话：023-67456888</a>
</div>
  </div>
</template>

<script>
export default {
  name: "Footer",

}
</script>

<style scoped>
.container{
  width: 100%;
  height: 90px;
  background: #F8F8F8;
  font-size: 11px;
  color: #999999;
}
.phone{
  display: block;
  margin-top: 8px;
  color: #999999;
}

</style>